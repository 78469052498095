import {FuseNavigationItem} from "@fidcare/base-gui-lib";


export const hopNavigation: FuseNavigationItem[] = [
    {
        id: 'home',
        title: 'Home',
        type: 'basic',
        icon: 'mat_outline:home',
        link:  '/',
    }
   
];
