<page-content [label]="'Homepage' | translate" contentMargin="0" contentClass="homepage-top-level-styling"
>
    <ng-container content style="margin: auto; padding: 0px;">
		<div class="homepage-button-grid" [style.grid-template-columns]="gridTemplateColumns">
			<div *ngFor="let button of buttons; let i = index" class="homepage-button" (click)="switchTo(button.url, button.newTab)">
				<div class="homepage-button-square" [style.background]="buttonBackgrounds[i]">
					<img
						src="assets/button-icons/{{ button.buttonIcon.filename }}.png"
						class="homepage-button-icon"
						*ngIf="button.buttonIcon" />
					<span class="homepage-button-label">{{ button.label | uppercase }}</span>
				</div>
			</div>
		</div>
    </ng-container>
</page-content>