import { FuseNavigationItem } from "@fidcare/base-gui-lib";

export const mainNavigation: FuseNavigationItem[] = [
    {
        id: 'home',
        title: 'Home',
        type: 'basic',
        icon: 'heroicons_solid:home',
        link: '/home'
    }
];

export const navigation = new Map<string, FuseNavigationItem[]>();
navigation.set("/", mainNavigation);