import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ComponentsModule, SharedModule} from '@fidcare/base-gui-lib';
import {FuseNavigationItem} from "@fidcare/base-gui-lib";
import { hopNavigation } from './hop-navigaton';
import { HOPHomePage } from './pages/home-page/home-page.component';
import { ButtonService, TabService } from '@fidcare/hop-api';
import { Route, RouterModule } from '@angular/router';

export const homeRoutes: Route[] = [
	  {
		  path: '',
		  component: HOPHomePage
	  }
]
@NgModule({
    imports: [
        ComponentsModule,
        CommonModule,
        SharedModule,
        CommonModule,
		RouterModule.forChild(homeRoutes)
    ],
    declarations: [
		HOPHomePage
    ],
    exports: [
        
    ],
    providers: [
    ]
})
export class HOPModule {
    public static getNavigationItems = (): FuseNavigationItem[] => {
        return hopNavigation;
    }
}