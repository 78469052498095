// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const API_BASE_PATH =  `${window.location.origin}`;

export const environment = {
	production: false,
	authentication: {
		issuer:  `${API_BASE_PATH}/auth/realms/kiranet`,
		silentRefreshRedirectUri: "/assets/authentication/silent-refresh.html",
		clientId: "frontend",
		responseType: "code",
		scope: "openid profile roles phone address",
		redirectUri: null,
		postLogoutRedirectUri: null,

	},
	forms: {
		"appearance": "outline",
		"floatLabel": "auto"
	},
	culture: {
		resourcePaths: [
			"./assets/i18n/{code}.json",
			"assets/i18n/hop-{code}.json"
		],
		cultureCodes: [
			"en",
			"it"
		],
		defaultCultureCode: "it"
	},
	services: {
		api: {
			serviceBasePath: `${API_BASE_PATH}`,
			dtdBasePath: `${API_BASE_PATH}/api/dtd`,
			pasBasePath: `${API_BASE_PATH}/api/pas12`,
			ddlBasePath: `${API_BASE_PATH}/api/ddl`,
			hfmBasePath: `${API_BASE_PATH}/api/hfm`,
			hopBasePath: `${API_BASE_PATH}/api/hop`,
			rcsBasePath: `${API_BASE_PATH}/api/rcs`
        }
	}
};
