import { Injectable } from '@angular/core';
import { ButtonService } from '@fidcare/hop-api';
import { AuthService, ActionButtonDescription, ActionButtonsDescriptionProvider } from '@fidcare/base-gui-lib';
import jwt_decode from "jwt-decode";
import { Observable, map, of } from 'rxjs';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';


@Injectable({
    providedIn: 'root'
})
export class ActionButtonProvider implements ActionButtonsDescriptionProvider{
	private buttons: ActionButtonDescription[];
	isLoading: boolean;
	userRoles: string[];

    constructor(
		private readonly authService: AuthService,
		private readonly buttonsService: ButtonService,
		private readonly router: Router
	) {
		
	}
	public getButtons = (): Observable<ActionButtonDescription[]> => {
		if (this.buttons) return of(this.buttons);
		else return this.fetchData();
	}

	private fetchData = (): Observable<ActionButtonDescription[]> => {
		let token: any = jwt_decode(this.authService.getAccessToken());
		this.userRoles = (token.realm_access && token.realm_access.roles)? token.realm_access.roles.map(r => r.toLowerCase()): [];
		return this.buttonsService.getAllByRoles(this.userRoles).pipe(
			map((result: any) => {
				this.buttons = (result || []).map((button) => {
					button.buttonIcon.filename = "assets/button-icons/"+button.buttonIcon.filename+".png";

					button.handleClick = () => {
						console.log("click on button ", button);
						const urlHasProtocol = button.url.startsWith('https://') || button.url.startsWith('http://') || button.url.startsWith('ftp://');
						if (button.newTab) {
							if (urlHasProtocol) {
								window.open(button.url, '_blank');
							} else if (button.url == 'settings/list') {
								window.open('https://' + button.url, '_blank');
							} else {
								window.open(environment.services.api.serviceBasePath + '/' + button.url, '_blank');
							}
						} else {
							if (urlHasProtocol) {
								location.href = button.url;
							} else if (button.url.startsWith('www.')) {
								location.href = 'https://' + button.url;
							} else if (button.url == 'settings/list') {
								this.router.navigate([button.url]); // relative url handled by routing
							} else {
								location.href = environment.services.api.serviceBasePath + '/' + button.url;
							}
						}
					}

					return button;
				})
				return this.buttons;
			})
		)
	}

}