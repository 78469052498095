import { Route } from "@angular/router";
import { LayoutComponent, ProfileModule } from "@fidcare/base-gui-lib";
import { navigation } from "./navigation";
import { HOPModule } from "./hop-module/hop.module";
import { hopNavigation } from "./hop-module/hop-navigaton";

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
  // Redirect empty path to '/example'
  { path: "", pathMatch: "full", redirectTo: "/homepage" },

    {
        path: "homepage",
        component: LayoutComponent,
        data: {name: "homepage"},
        loadChildren: ()=> import("./hop-module/hop.module").then((m)=>m.HOPModule).then((module) => {
			navigation.set("homepage", HOPModule.getNavigationItems());
			return module;
		})
               
    },
	{
		path: "profile",
		component: LayoutComponent,
		data: { name: "profile" },
		loadChildren: () => {
			navigation.set("profile", ProfileModule.getNavigationItems());
			return ProfileModule;
		}
	},
	{
		path: 'settings',
		component: LayoutComponent,
		data: { name: "settings" },
		loadChildren: ()=> import('./hop-module/settings/settings.module').then((m) => m.SettingsModule).then((module) => {
			navigation.set("settings", hopNavigation);
			return module;
		})
	},
	
];

